.feature-section {
    position: relative;
    padding: 120px 0 60px;
}
.auto-container {
    position: static;
    max-width: var(--container-width);
    padding: 0 15px;
    margin: 0 auto;
    width: 100%;
}
.feature-row .image-column .inner-column {
    margin-right: -170px;
    position: relative;
    z-index: 9;
}
.feature-row .image-column .image {
    overflow: hidden;
}

figure.image {
    margin-bottom: 0;
}
.overlay-anim {
    position: relative;
}
figure {
    margin: 0 0 1rem;
}
.feature-row .content-column {
    margin-bottom: 60px;
}
.feature-row .content-column .inner-column {
    position: relative;
    background-color: #f8f5f0;
    padding: 61px 0 70px 231px;
    margin-top: 50px;
}
.feature-row .content-column .inner-column::before {
    content: "";
    position: absolute;
    top: 0;
    left: 232px;
    width: 95px;
    height: 11px;
    background-color: #84226b;
}
.feature-row .content-column .inner-column .sec-title {
    margin-bottom: 34px;
}

.sec-title {
    position: relative;
    margin-bottom: 49px;
}
.feature-row .content-column .inner-column .sec-title .sub-title {
    margin-bottom: 11px;
}
.sec-title .sub-title {
    position: relative;
    font-size: 18px;
    color: #84226b;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 14px;
   font-family: 'Jost', sans-serif;
    letter-spacing: 7.8px;
}

.feature-row .content-column .inner-column .sec-title .sub-title {
    margin-bottom: 11px;
}

.sec-title .sub-title {
    position: relative;
    font-size: 18px;
    color: #84226b;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 14px;
    font-family: 'Jost', sans-serif;
    letter-spacing: 7.8px;
}
.sec-title h2 {
    position: relative;
    display: block;
    font-size: var(--sec-title-font-size);
    color: var(--sec-title-color);
    font-family: 'Jost', sans-serif;
    font-weight: var(--sec-title-font-weight);
    margin-bottom: 0;
    z-index: 2;
}
.feature-row .content-column .inner-column .sec-title .text {
    margin-top: 31px;
}
.btn-style-one {
    position: relative;
    font-size: 16px;
    line-height: 25px;
    padding: 14px 32px 18px;
    overflow: hidden;
    color: #84226b;
    border: 1px solid #84226b;
    background: #f8f5f0;
    text-transform: uppercase;
    z-index: 9;
}
.feature-row .content-column .inner-column .image-2 {
    position: absolute;
    top: 18px;
    right: 13px;
}
figure {
    margin: 0 0 1rem;
}
img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}
.btn-style-one {
    position: relative;
    font-size: 16px;
    line-height: 25px;
    padding: 14px 32px 18px;
    overflow: hidden;
    color:#84226b;
    border: 1px solid #84226b;
    background: #f8f5f0;
    text-transform: uppercase;
    z-index: 9;
}
.feature-row:nth-child(even) .content-column .inner-column {
    padding: 61px 231px 70px 60px;
}
.feature-row:nth-child(even) .image-column .inner-column {
    margin-right: 0;
    margin-left: -170px;
}
.feature-row .image-column .image {
    overflow: hidden;
}
figure.image {
    margin-bottom: 0;
}
.overlay-anim {
    position: relative;
}
.overlay-anim:before {
    background:#84226b;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    opacity: 1;
    z-index: 9;
    pointer-events: none;
}
.feature-row .image-column .image img {
    width: 100%;
    height: 410px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

@media(max-width:992px){
    .feature-row .image-column .inner-column {
        margin-right: 0px;
    }

    .feature-row .content-column .inner-column {
        position: relative;
        background-color: #f8f5f0;
        padding: 61px 0 70px 30px;
        margin-top: -12px;
    }
    .feature-row:nth-child(even) .image-column .inner-column {
        margin-right: 0;
        margin-left: 0px;
    }
}

@media(max-width:576px){
    .feature-row .image-column .image img {
        width: 100%;
        height: 190px;
        transition: all .3s ease;
    }
    .feature-row .content-column .inner-column .sec-title {
        /* margin-bottom: 34px; */
    }
    .feature-row:nth-child(even) .content-column .inner-column {
        padding: 61px 30px 70px 30px;
    }
    
.feature-row .content-column .inner-column::before {
    content: "";
    position: absolute;
    top: 0;
    left: 30px;
    width: 95px;
    height: 11px;
    background-color: #84226b;
}   
}