.dismiss svg {
    color: var(---TheamGoldColor);
}

.overlay img{
    border-radius: 5px;
    box-shadow: 0 0 5px maroon;
}

.modal-open .modal {
background-color: rgba(59, 52, 52, 0.9) !important;
}
