.rooms-section {
    position: relative;
    padding: 120px 0;
    background: #f8f5f0
}

@media(max-width: 575.98px) {
    .rooms-section .sec-title {
        margin-bottom:40px
    }
}

@media(max-width: 575.98px) {
    .rooms-section .sec-title h2 {
        font-size:32px
    }
}

.room-block.col-lg-3 .inner-box .box-caption .bx-links li {
    margin-right: 3px
}

.room-block .inner-box {
    position: relative
}

.room-block .inner-box:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear,right top,left top,from(#222222),to(#222222));
    background: linear-gradient(to left,#222222,#222222);
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.room-block .inner-box:hover .box-caption {
    opacity: 1;
    visibility: visible;
    bottom: 30px
}

.room-block .inner-box:hover .content-box {
    bottom: 84px;
    z-index: 9
}

.room-block .inner-box:hover:before {
    opacity: .4;
    visibility: visible
}

.room-block .inner-box:hover .image-box figure img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.room-block .inner-box .box-caption {
    position: absolute;
    left: 30px;
    width: calc(100% - 60px);
    z-index: 99;
    border-top: 1px solid rgba(255,255,255,.5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    opacity: 0;
    visibility: hidden;
    bottom: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.room-block .inner-box .box-caption .book-btn {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    text-transform: uppercase;
    font-size: 14pxx;
    color: #fff;
    font-family: var(--text-font2);
    letter-spacing: 2px;
    border: 1px solid rgba(255,255,255,.5)
}

.room-block .inner-box .box-caption .book-btn:hover {
    background-color: var(--theme-color1);
    border-color: transparent
}

.room-block .inner-box .box-caption .bx-links {
    margin-left: auto
}

.room-block .inner-box .box-caption .bx-links li {
    display: inline-block;
    margin-right: 11px
}

.room-block .inner-box .box-caption .bx-links li:last-child {
    margin-right: 0
}

.room-block .inner-box .box-caption .bx-links li a {
    color: #fff;
    font-size: 16px
}

.room-block .inner-box .image-box figure {
    margin-bottom: 27px;
    overflow: hidden
}

.room-block .inner-box .image-box figure img {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -o-object-fit: cover;
    object-fit: cover
}

.room-block .inner-box .content-box {
    position: absolute;
    bottom: 22px;
    right: 32px;
    text-align: right;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 9
}

.room-block .inner-box .content-box .title {
    font-size: 27px;
    margin-bottom: -6px;
    color: #84226b;
}

.room-block .inner-box .content-box .price {
    font-size: 14px;
    color: var(--theme-color-light)
}

@media(max-width: 991.98px) {
    .room-block .inner-box .image-box .image-2 img {
        width:100%;
        height: 340px;
        -o-object-fit: cover;
        object-fit: cover
    }
}

.room-facility-list {
    position: relative
}

.room-facility-list .icon {
    border: 1px solid var(--theme-color1);
    border-radius: 50%;
    display: block;
    font-size: 22px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    width: 64px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease
}

.room-facility-list .list-one:hover .icon {
    background-color: var(--theme-color1);
    color: #fff
}

.room-details__content-right {
    position: relative;
    display: block;
    margin-top: 40px
}

.room-details__details-box {
    background-color: #f5f6f7;
    border: 1px solid var(--theme-color1);
    border-radius: 10px;
    padding: 43px 50px 30px;
    position: relative;
    z-index: 1
}

.product-details.rd-page .bx-wrapper {
    margin-bottom: 30px
}

.product-details.rd-page .bx-wrapper .thumb-box li {
    height: auto
}

@media only screen and (max-width: 480px) {
    .room-block .inner-box .box-caption .book-btn {
        padding:0 10px;
        margin-right: 10px
    }

    .room-block.col-lg-3 .inner-box .box-caption .bx-links li {
        margin-right: 10px
    }

    .rooms-section {
        padding: 80px 0
    }
}
