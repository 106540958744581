.img-fluid {
    max-width: 100%;
    /* height: auto; */
}


.specials {
    font-family: "Open Sans", sans-serif;
    background: #0c0b09;
    color: #fff;
}

.specials .nav-link {
    border: 0;
    padding: 12px 15px;
    transition: 0.3s;
    color: #fff;
    border-radius: 0;
    border-right: 2px solid #cda45e;
    font-weight: 600;
    font-size: 15px;
}

.specials .nav-link.active {
    color: #1a1814;
    background: #cda45e;
    border-color: #cda45e;
}





.specials .nav-link {
    border: 0;
    padding: 12px 15px;
    transition: 0.3s;
    color: #fff;
    border-radius: 0;
    border-right: 2px solid #cda45e;
    font-weight: 600;
    font-size: 15px;
}

.special-offer .outer-container:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 100%;
    background: url(https://kalanidhithemes.com/live-preview/landing-page/delici/all-demo/Delici-Defoult/images/background/pattern-9.svg    ) center repeat;
}
.special-offer .outer-container:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    height: 100%;
    background: url(https://kalanidhithemes.com/live-preview/landing-page/delici/all-demo/Delici-Defoult/images/background/pattern-9.svg) center repeat;
}

.special-offer .owl-theme .owl-nav {
    position: absolute;
    left: -100px;
    right: -100px;
    height: 0;
    top: 50%;
    margin-top: -22px;
}
.special-offer .owl-theme .owl-nav .owl-prev {
    left: 15px;
}
.owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    display: inline-block;
    vertical-align: top;
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: var(--font-24);
    text-align: center;
    background: transparent;
    color: var(--main-color);
    border-radius: 0%;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
}
.owl-next, .owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.special-offer .owl-theme .owl-nav .owl-next, .special-offer .owl-theme .owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    display: inline-block;
    vertical-align: top;
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: var(--font-24);
    text-align: center;
    background: transparent;
    color: var(--main-color);
    border-radius: 0%;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
}
.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}